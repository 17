import React from 'react'
import MainContent from '../MainContent'
import WelcomeContent from '../WelcomeContent'


const Home = ({isOpenCorona, toggleCorona}) =>{
    return(
        <div>
            <WelcomeContent   />
            <MainContent/>
        </div>
    )
}


export default Home