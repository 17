import React from 'react'
import {Link} from 'react-router-dom'
import { MainContentContainer,MainContentText,MainContentImageWrapper,MainContentImageWrapperSecond,MainContentImage,Button,TextP,FacebookContainer,FacebookItem,Quote,IconContainer,Icon,MainContentImageWrapperMobile} from './elements'
import img from '../../images/website1.jpg'
import Praktijk from '../Praktijk'
import {FaFacebook} from "react-icons/fa"

const MainContent = () =>{

    return(
        <div> 
           

           <FacebookContainer>
                <FacebookItem>
                    <Quote>"
                        zijn allemaal super lief naar de kinderen toe.
                        kan het aanraden voor alle kindjes.mijn dochtertje was vreselijk angstig.
                         en daar zijn ze heel goed in om het de kindjes op gemak te stellen
                            heel blij mee <span role="img" aria-label="donut">🍩</span> "
                            </Quote>
                            <IconContainer>
                                <Icon>
                                <a style = {{color:'inherit'}} href="https://nl-nl.facebook.com/pg/JeugdTandartsSchiedam/reviews/?ref=page_internal"> <FaFacebook /></a>
                                </Icon>
                            </IconContainer>
                            
                    
                </FacebookItem>

                <FacebookItem>
                <Quote>"
                Hele vriendelijke team, goede uitleg en tijd genomen voor onze zoon die een nare ervaring had gehad, 
                het ging perfect hij was niet bang werkte goed mee. 
                We waren met blijdschap de deur uit. Echt een aanrader.
                Het belangrijkste was ook dat jullie een dikke duim krijgen van onze zoon."
                    </Quote>
                    <IconContainer>
                                <Icon>
                                <a style = {{color:'inherit'}} href="https://nl-nl.facebook.com/pg/JeugdTandartsSchiedam/reviews/?ref=page_internal"> <FaFacebook /></a>
                                </Icon>
                            </IconContainer>
                </FacebookItem>
                <FacebookItem>
                <Quote>"
                Een professioneel team die echt de tijd neemt voor de kleintjes.
                Ze stellen de patient gerust en hebben een prettige wachtruimte (mooi speelgoed;)
                waardoor kinderen niet eens in de gaten hebben dat ze wachten.
                Heel belangrijk: De praktijk is echt schoon!
                Ik kan deze praktijk dan ook hartelijk aanbevelen aan alle ouders die op zoek zijn naar een kindvriendelijke tandarts. 
                Dank je wel @JeugdTandartsSchiedam"
                </Quote>
                    <IconContainer>
                                <Icon>
                                <a style = {{color:'inherit'}} href="https://nl-nl.facebook.com/pg/JeugdTandartsSchiedam/reviews/?ref=page_internal"> <FaFacebook /></a>
                                </Icon>
                            </IconContainer>
                </FacebookItem>
               
               
                

                
            </FacebookContainer>
            
        <MainContentContainer style = {{background:'#e8e6e6'}}>
            <MainContentText >
                <h1 style={{textAlign: "left", }}>Elk kind een gezond gebit!</h1>
                <TextP >
                    Een gezond melkgebit is belangrijk voor een gezond blijvend gebit, 
                    daarom is het van belang om de tandarts al te bezoeken op een vroege leeftijd. 
                    Kinderen krijgen ruim de tijd en de aandacht. 
                    Wij vinden het heel belangrijk dat uw kind een tandartsbezoek prettig zal ervaren. 
                    <br/><br/>
                    De kinderen worden op een kindvriendelijke manier gewend aan de tandartsbezoeken. 
                    Voor angstige kinderen hebben we wentrajecten, waarbij we alles uitleggen en laten zien (tell-show- do methode).
                    De peuters leren op speelse wijze kennis te maken met de tandarts...
                    <br/><br/>
                    </TextP>
                    <Link to = 'missie'>
                    <Button>  Lees meer</Button>
                    </Link>
                 
            </MainContentText>
    

            <MainContentImageWrapper>
                <MainContentImage src = {img}>
                </MainContentImage>
            </MainContentImageWrapper>
       </MainContentContainer>
       <MainContentContainer style = {{paddingTop:'50px'}}>
    
            <MainContentImageWrapperSecond >
                <div style = {{marginLeft:'10%'}}>
                <Praktijk />
                </div>
                
            </MainContentImageWrapperSecond >
            <MainContentText>
                <h1 style={{textAlign: "left"}}>Onze praktijk</h1>
                <TextP >
                Jeugd Tandarts Schiedam is een jonge en dynamische praktijk voor jeugd van 0 tot 18 jaar.
                <br/> Wij zijn als praktijk gestart in maart 2017 in hartje Schiedam, aan de Westvest 14.
                  <br/>De kinderen en ouders worden warm verwelkomd in onze kindvriendelijke wachtruimte.
                   Kinderen kunnen zich prima vermaken en ontspannen voordat ze naar de behandelkamer worden begeleid.
                    <br/><br/>
                    </TextP>
                   
            </MainContentText>

            <MainContentImageWrapperMobile >
                <div style = {{marginLeft:'5%'}}>
                <Praktijk />
                </div>
                
            </MainContentImageWrapperMobile >
       </MainContentContainer>
      
   </div>
    )
}

export default MainContent