import React, { useState } from 'react';
import {PageContainer,ItemContainer,ItemWrapper,ButtonContainer,Button,Img,TextP,Icon,IconContainer, HiddenDiv1,HiddenDiv2, CenterDiv} from './elements'
import Header from '../Header'
import char from '../../images/8.svg'
import char2 from '../../images/2.svg'
import char3 from '../../images/3.svg'
import char4 from '../../images/7.svg'
import char5 from '../../images/4.svg'
import {FaInfoCircle,FaTimes} from 'react-icons/fa'


const Team = ({isOpenTeam,toggleTeamTrue,toggleTeamFalse,text}) =>{
    const [isOpenDiv1,setIsOpenDiv1] = useState(false)
    const toggleDiv1= () =>{
        setIsOpenDiv1(!isOpenDiv1)
        
      }
      const [isOpenDiv2,setIsOpenDiv2] = useState(false)
      const toggleDiv2= () =>{
          setIsOpenDiv2(!isOpenDiv2)
          
        }


    return(
        <div>
            <Header text = {text}></Header>
            <ButtonContainer>
             <Button isOpenTeam = {isOpenTeam} onClick = {toggleTeamFalse}>Tandartsen</Button>
             <Button isOpenTeam = {!isOpenTeam} onClick = {toggleTeamTrue}>Ons Team</Button>
           </ButtonContainer>
       <PageContainer isOpenTeam = {isOpenTeam}>
           

           <ItemContainer>
           <ItemWrapper>
                    
                   <Img src ={char}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px'}}>Zeynep Yaramis</TextP>
                   <TextP >Tandarts</TextP>
                   <TextP>Bignummer: 19911300202</TextP>
                   
                   <IconContainer>
                   <Icon>{!isOpenDiv1 ?<FaInfoCircle onClick={toggleDiv1}/>:<FaTimes onClick={toggleDiv1}/> }</Icon>
                   </IconContainer>
                   <HiddenDiv1 isOpenDiv1 = {isOpenDiv1}>
                       <TextP >Ik ben in 2009 afgestuurd aan de ACTA. 
                              Na 7 jaar gewerkt te hebben in een algemene tandartspraktijk wilde ik mij toch richten tot de kindertandheelkunde.
                              Een bijzonder zorggroep waarbij ook speciale aandacht voor nodig is.
                             <br/> Elk kind is weer een unieke uitdaging.<br/> Mijn doel is dat ieder kind blij en trots en natuurlijk pijnvrij de tandartsstoel verlaat.
                              </TextP>
                   </HiddenDiv1>
        
               </ItemWrapper>
               <ItemWrapper>
                   <Img src ={char2}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px'}}>Iffet Yaramis</TextP>
                   <TextP>Tandarts</TextP>
                   <TextP>Bignummer: 19915572002</TextP>
                   
                   <IconContainer>
                   <Icon>{!isOpenDiv2 ?<FaInfoCircle onClick={toggleDiv2}/>:<FaTimes onClick={toggleDiv2}/> }</Icon>
                    </IconContainer>
                   <HiddenDiv2 isOpenDiv2 = {isOpenDiv2}>
                       <TextP >Ik heb in 2012 mijn tandartsenopleiding voltooid aan de ACTA in Amsterdam. 
                           Snel na mijn afstuderen ben ik aan de slag gegaan als kindertandarts. 
                           Na 5 jaar ervaring opgedaan te hebben in een aantal praktijken,
                            hebben we samen met mijn zus besloten een eigen jeugdtandartspraktijk te beginnen. 
                            Dag in dag uit vind ik het weer heerlijk om met kinderen te werken.<br/> Ons doel voor de toekomst: <br/>
                            Gaatjesvrije gebitten!
                              </TextP>
                   </HiddenDiv2>

               </ItemWrapper>
               
           </ItemContainer>

           <ItemContainer>
                <ItemWrapper>
                <Img src ={char3}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px'}}>Merve Orta</TextP>
                   <TextP>Tandarts</TextP>
                   <TextP>Bignummer: 29927990302</TextP>
                </ItemWrapper>

                <ItemWrapper>

                <Img src ={char4}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px'}}>Siham Ouchen</TextP>
                   <TextP>Tandarts</TextP>
                   <TextP>Bignummer: 39928260402</TextP>
                    
                </ItemWrapper>

                <ItemWrapper>
                <Img src ={char}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px'}}>Fatima Zamri</TextP>
                   <TextP>Tandarts</TextP>
                   <TextP>Bignummer: 6992799102</TextP>
                    
                </ItemWrapper>
               
            </ItemContainer>

       </PageContainer>

       <PageContainer isOpenTeam = {!isOpenTeam}>


       <ItemContainer>
                <ItemWrapper>
                <Img src ={char5}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px'}}>Eyup Yaramis</TextP>
                   <TextP>Praktijk Manager</TextP>
                </ItemWrapper>
               
            </ItemContainer>
           

       <ItemContainer>
                <ItemWrapper>
                <Img src ={char4}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px'}}>Feyza Hira</TextP>
                   <TextP>Assistent</TextP>
                </ItemWrapper>

                <ItemWrapper>

                <Img src ={char}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px'}}>Debbie Camonier</TextP>
                   <TextP>Assistent</TextP>
                    
                </ItemWrapper>

               
               
            </ItemContainer>
            <ItemContainer>
            <ItemWrapper>
                <Img src ={char2}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px'}}>Cemre Senyürek</TextP>
                   <TextP>Assistent</TextP>

                    
                </ItemWrapper>
                <ItemWrapper>
                <Img src ={char3}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px'}}>Tugba Aksu</TextP>
                   <TextP>Assistent</TextP>

                    
                </ItemWrapper>

                </ItemContainer>

       </PageContainer>


       </div>

    )
}

export default Team