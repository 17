import styled from 'styled-components'

export const ContactContainer = styled.div`

display:block;
width:100%;
height:100%;
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

@media screen and (max-width:1030px){
  width:95%;
  margin-left:3%;
      
}
`;

export const ContactWrapper = styled.div`
display:flex;
width:100%;
height:100%;
@media screen and (max-width:1030px){
  display:block;
      
}

justify-content:space-evenly;

`;

export const TextH1 = styled.h2`
text-align:left;
color:#08bfc0;

`;

export const TextP = styled.p`
margin-left:10%;

font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
@media screen and (max-width:1030px){
  margin-left:3%;
      
}

`;

export const Icon = styled.div`
margin-left: 10px;
margin-top: 3px;
cursor: pointer;
&:hover{
  color: gray;
}
`;

export const IconContainer = styled.div`
display: flex;

align-items:center;
@media screen and (max-width: 950px){
  margin-right:30px;
}

`;

export const ItemContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;   
`;

export const Item = styled.div`
text-align:left;
display:flex;

color:black;
width:150%;
`

export const IFrame = styled.iframe`

height:400px;
width:100%;
`