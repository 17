import styled from 'styled-components'

export const PageContainer = styled.div`
display:flex;
width:100%;
margin-bottom:50px;

;

@media screen and (max-width:1030px){
        display:block;
        margin-bottom:0;
        margin-right:100px;


}`;

export const PageItem = styled.div`
display:flex;
@media screen and (max-width:1030px){
    margin-left:2%;
}`;


export const TextH1 = styled.h1`
    text-align:left;
    color:#08bfc0;
    
    @media screen and (max-width:1030px){
        
        margin-left:5%;
      
}
`;



export const FormContainer = styled.div`

display:flex;
background:linear-gradient(145deg, rgba(21,111,110,1) 0%, rgba(106,210,213,1) 100%);
border-radius:10px;
padding:25px 30px 25px 25px;

margin-bottom:50px;
margin-right:50px;
box-shadow: 6px 7px 15px -2px rgba(0,0,0,0.45);
@media screen and (max-width:1030px){
        display:block;
        padding:0px;  
        margin-left:5%;

        padding-right:12px;
        background:white;
        width:100%;
}

`;

export const FormWrapper = styled.div`
    margin-left:20px;
    display:flex;
    background:white;
    border-radius:10px;
    width:350px;
    margin-top:10px;
    padding:10px;
    box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.45);
    @media screen and (max-width:1030px){
        width:100%;
        
        margin-left:0;
       

      
}
`;

export const Form = styled.form`

`;

export const InputText = styled.input`
    width:90%;
    padding:10px;
    margin-top:10px;
    float:left;
    
`;
export const InputRadio = styled.input`
    float:left;
`;

export const Submit = styled.input`
width:50%;
float:left;
padding:10px;
margin-top:35px;
font-weight:bold;
font-size:15px;
border-radius:5px;
background:#44cecd;
border:none;
color:white;
cursor:pointer;
`;

export const TextContainer = styled.div`
display:flex;
float:left;
width:100%;
`;

export const Header = styled.div`
    display:flex;
    margin-top:10px;
    width:100%;
    height:50px;
    
    justify-content:center;
    align-content:content;
    flex-direction:column;
    box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.76);

`;

export const SingleDiv = styled.div`
margin-left:20%;
@media screen and (max-width:1030px){
        
       display:none;
      
}
`;

export const TextP = styled.div`

width:100%;
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
text-align:left;
font-size:20px;
@media screen and (max-width:1030px){
        margin:0;
        margin-left:5%;
        margin-bottom:10%;
        width:90%;
      
}
`;

export const TextDiv = styled.div`
margin-left:20%;
width:50%;
padding:30px;
border-radius:10px;
text-align:left;
box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.1);

@media screen and (max-width:1030px){
        margin-left:0;
        width:82%;
        margin-bottom:2%;
      
}

`;
export const ClipDiv = styled.div`
margin-top:10%;
float:right;
margin-right:5%;


@media screen and (max-width:1030px){
       
      
}

`;

