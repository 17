import styled from 'styled-components'

export const PageContainer = styled.div`
`

export const BehandelenContainer = styled.div`
    display:flex;
    width:100%;
    
    justify-content:center;
    @media screen and (max-width:1030px){
        display:block;
        margin-top:10px;
        
    }
`;

export const BehandelenWrapper = styled.div`
    padding:30px;
    width:25%;
    height:80%;
    @media screen and (max-width:1030px){
       width:100%;
       padding:0;
        
    }

`;
export const BehandelenWrapperSecond = styled.div`
    padding:30px;
    width:25%;
    height:80%;
    @media screen and (max-width:1030px){
       display:none;
        
    }

`;



export const BehandelenWrapperMobile = styled.div`
    display:none;
    padding:30px;
    width:25%;
    height:80%;
    @media screen and (max-width:1030px){
        display:block;
       width:100%;
       padding:0;
       height:80%;
        
    }

`;

export const TextHeader = styled.h1`
    width:100%;
    text-align: left;
    color:#08bfc0;
`;

export const Text = styled.p`
text-align: left;
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    
`;

export const Img = styled.img`
width:90%;
border-radius:20px;
margin-top:2%;

`;

export const TextDiv = styled.div`
padding:10px;
border-radius:10px;
box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.1);
`



