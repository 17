import React from 'react'
import {ContactContainer, ContactWrapper, TextH1, TextP,Icon,IconContainer,ItemContainer,Item,IFrame} from './elements'
import {FiPhoneCall} from 'react-icons/fi'
import {AiOutlineMail} from 'react-icons/ai'
import {HiOutlineLocationMarker} from 'react-icons/hi'
import Header from '../Header'

const Contact = ({text}) =>{

    return(
            <div>
                <Header text= {text}></Header>
            <ContactContainer>
                <ContactWrapper style = {{marginBottom:'8%'}}>
                    <ItemContainer>
                        <TextH1>
                            Contact
                        </TextH1>
                        <Item>

                
                            <IconContainer>
                                <Icon>
                                    <HiOutlineLocationMarker/>
                                </Icon>
                            </IconContainer>
                            
                            <TextP>Westvest 14 <br/> 3111 BV Schiedam</TextP>
                

                        </Item>

                        <Item>

                
                            <IconContainer>
                                <Icon>
                                    <FiPhoneCall/>
                                </Icon>
                            </IconContainer>

                            <TextP>010-304 82 70</TextP>


                        </Item>
                        <Item>

                
                            <IconContainer>
                                <Icon>
                                    <AiOutlineMail/>
                                </Icon>
                            </IconContainer>

                            <TextP>info@jtschiedam.nl</TextP>


                        </Item>
                        
                    </ItemContainer>

                    <ItemContainer>
                    <TextH1>
                        Openingstijden
                     </TextH1>
                     <Item>Maandag:&nbsp;&nbsp; &emsp; 9.00 – 16.30
                    </Item> 
                    <Item>Dinsdag: &nbsp;&nbsp;&nbsp;&nbsp;&emsp; 9.00 – 16.30
                    </Item> 
                    <Item>woensdag:&nbsp;&nbsp;&emsp;  9.00 – 16.30
                    </Item> 
                    <Item>Donderdag:&emsp;  9.00 – 16.30
                    </Item> 
                    <Item>Vrijdag: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &emsp;9.00 – 16.30
                    </Item>   
                     </ItemContainer>

                     <ItemContainer>
                    <TextH1>
                        Gesloten dagen
                     </TextH1>
                     <Item >- &ensp;Nieuwjaarsdag: vrijdag 1 januari 2021
                    </Item> 
                    <Item >- &ensp;Goede vrijdag: vrijdag 2 april 2021
                    </Item> 
                    <Item>- &ensp;Pasen maandag 5 april 2021
                    </Item> 
                    <Item >-&ensp; Koningsdag: dinsdag 27 april 2021
                    </Item> 
                    <Item >- &ensp;Bevrijdingsdag: woensdag 5 mei 2021
                    </Item>  
                    <Item >- &ensp;Hemelvaartsdag: donderdag 13 mei 2021
                    </Item> 
                    <Item >- &ensp;Pinksteren  maandag 24 mei 2021
                    </Item>
                    <Item >- &ensp;Zomersluiting 6-29 augustus 2021
                    </Item>   
                     </ItemContainer>
                </ContactWrapper >


                <p style = {{fontStyle:'italic'}}>Wij adviseren om te parkeren in de parkeergarage van de Nieuwe-passage Schiedam (hier is eerste uur gratis parkeren), het is op 5 min loopafstand van de praktijk .</p>
                <ContactWrapper>
                   
                <IFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2461.100752581186!2d4.397432515368714!3d51.91387287970397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c43514ba4fda9d%3A0xfe7f1106c36cbddd!2sWestvest+14%2C+3111+BV+Schiedam!5e0!3m2!1snl!2snl!4v1486685320972"></IFrame>
            
                
                </ContactWrapper>
                


            </ContactContainer>
            </div>
    )

}

export default Contact