import React from 'react'
import {SpoedgevalContainer,TextH2,TextP} from './elements'
import Header from '../Header'

const Spoedgeval = ({text}) =>{

    return(
        <div>
        <Header text = {text}/>
        <SpoedgevalContainer>
            <TextH2>Spoedgeval</TextH2>
            <TextP>
            In geval van spoed kunt u bellen naar de nummer van de praktijk <b>010-3048270</b>.
            <br/>
            Tijdens werktijden (ma t/m vr 9.00-16.30) zal de baliemedewerker u te woord staan.
            <br/> <br/>
            Buiten deze tijden en vakantiedagen belt u naar nummer <b>010 455 21 55</b>.
            <br/>
            Onze spoedgevallen worden dan overgenomen door Tandartsenpost010. Deze bevindt zich in het Erasmus MC te Rotterdam.
            <br/>
            <br/>
            Bel alleen buiten werktijden in geval van:
            <br/><br/> &emsp;
                -Dringende klachten die niet tot de volgende dag kunnen wachten zoals een losgeslagen of uitgevallen tand.
                <br/>&emsp;
                -Nabloeding of ernstige kiespijn.
                <br/>
                <br/>
                Onze spoedgevallendienst is er uitsluitend voor  de patiënten (kinderen) die ingeschreven zijn bij Jeugd Tandarts Schiedam.

            </TextP>
        </SpoedgevalContainer>
        </div>

    )

}

export default Spoedgeval