import React from 'react'
import {Button, WelcomeContentWrapper} from './elements'
import {Link} from 'react-router-dom'
import {FaPen,FaHospital} from "react-icons/fa"
import CoronaUpdate from '../CoronaUpdate'


const WelcomeContent = ({toggleCorona, isOpenCorona}) =>{


    return(
        <WelcomeContentWrapper>
        <Link to = 'inschrijven'>
        <Button isOpenCorona = {isOpenCorona} > <FaPen/>&nbsp; Inschrijven</Button>
        </Link>
        </WelcomeContentWrapper>

    )


}

export default WelcomeContent