import styled from 'styled-components'



export const MainContentContainer = styled.div`
    display: flex;
    justify-content:space-around;
    padding-top:5%;
    padding-bottom:5%;
    margin-top:5%;
    flex-wrap:flex;
    @media screen and (max-width:1030px){
      padding-left:12px;
       display:block;
}
`;

export const MainContentText = styled.div`
width:500px;
font-size:19px;

@media screen and (max-width:1030px){       
       width:95%;    
      }
`;

export const MainContentImageWrapper = styled.div`
width:500px;

@media screen and (max-width:1030px){    
       margin-top:3%;
       margin-left:3%;
       width:350px;
       height:500px;
}
`;

export const MainContentImageWrapperSecond = styled.div`
width:500px;

@media screen and (max-width:1030px){    
      display:none;
}
`;





export const MainContentImageWrapperMobile = styled.div`
display:none;

@media screen and (max-width:1030px){    
       display:block;
       margin-top:3%;
       margin-left:3%;
       width:350px;
       height:500px;
}
`;


export const MainContentImage = styled.img`
    width:75%;
    height:auto;
    border-radius:10px;
    box-shadow:7px 5px 20px 0px rgba(0,0,0,0.6);
    float:left;
    @media screen and (max-width:1030px){
    width:100%;   
    
    }
`;

export const Button = styled.button`
font-size:18px;
width:auto;
height:auto;
font-weight:bold;
background:none;
border: 3px solid black;
padding: 15px;
border-radius: 5px;
cursor:pointer;
&:hover{
  background: #d2f2f2;
}
`;

export const TextP = styled.p`
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
text-align:left;
@media screen and (max-width:1030px){
      
}
`;

export const FacebookContainer = styled.div`
display:flex;
width:100%;
height:100%;
justify-content:space-around;
margin-top:5%;
@media screen and (max-width:1030px){       
       display:block;    
      }

`

export const FacebookItem = styled.div`
margin-left:5%;
width:20%;
padding:1%;
height:100%;
border-top-right-radius:30px;
border-top-left-radius:30px;
box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.46);
font-family:kalam;
@media screen and (max-width:1030px){       
       width:90%;  
       margin-bottom:5%;  
      }

`
export const Icon = styled.div`
font-size:40px;
color:#2336b0;
cursor: pointer;
&:hover{
  color: gray;
}
`;

export const IconContainer = styled.div`
display: flex;

margin-left:45%;
margin-top:10%;
@media screen and (max-width: 950px){
}

`;


export const Quote = styled.p`
text-align:left;
width:100%;
font-family: 'Kalam', cursive;

`;


