import React from 'react'
import {MissieContainer,MissieWrapper,TextContainer,TextH1,TextP,Img} from './elements'
import logo from '../../images/fb2.png'
import Header from '../Header'

const Missie = ({text}) =>{

    return(
        <div>
        <Header text = {text}/>
        <MissieContainer>

            <MissieWrapper>
                <TextContainer>
                    <TextH1>
                        Onze Missie
                    </TextH1>

                    <TextP>
                    Een gezond melkgebit is belangrijk voor een gezond blijvend gebit, 
                    daarom is het van belang om de tandarts al te bezoeken op een vroege leeftijd.
                    Kinderen krijgen ruim de tijd en de aandacht. 
                    Wij vinden het heel belangrijk dat uw kind een tandartsbezoek prettig zal ervaren.
                    De kinderen worden op een kindvriendelijke manier gewend aan de tandartsbezoeken.
                    Voor angstige kinderen hebben we wentrajecten, waarbij we alles uitleggen en laten zien (tell-show- do methode).
                   <br/> <br/> De peuters leren op speelse wijze kennis te maken met de tandarts. 
                    Ze worden uitgenodigd samen met leeftijdgenoten om de eerste bezoek aan de tandarts extra gezellig te maken. 
                    Tijdens deze eerste bezoek krijgt u als ouder advies onder andere over duimen/speengedrag, voeding en tandenpoetsen.
                    <br/>Bij iedere halfjaarlijkse controle hoort er een poets les bij.
                     De kinderen krijgen in de poetsruimte uitgebreid poetsinstructie. 
                     Ouders van kinderen tot 10 jaar worden betrokken bij de poetslessen.
                    </TextP>

                </TextContainer>
            </MissieWrapper>

            <MissieWrapper>
                <Img src = {logo}></Img>
            </MissieWrapper>

        </MissieContainer>
        </div>

    )


}

export default Missie