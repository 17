import styled from 'styled-components'

export const Container = styled.div`
height:200vw;
width:100%;
padding-top:100px;
background-color: ${({isOpenCorona}) =>(isOpenCorona ? 'rgba(105,105,105,0.7)': 'white')};;
display:${({isOpenCorona}) =>(isOpenCorona ? 'flex': 'none')};
@media screen and (max-width:1030px){    
       height:300vw;
       
}`;
export const TextContainer = styled.div`
background:white;
position: absolute;
width:50%;
height:70%;
opacity:100%;
left:26%;
top:27%;
border-radius: 10px;
@media screen and (max-width:1030px){    
       width:98%;
       left:2%;
       height:60%;
       top:25%;
       
}`;

export const HeaderContainer = styled.div`
display:flex;
padding-left:2%;
@media screen and (max-width:1030px){    
    font-size:13px;
       
}`;




export const TextWrapper = styled.div`
text-align:left;
padding-left:2%;
font-size:16px;
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
@media screen and (max-width:1030px){    
    font-size:12px;
       
}`;


export const Icon = styled.div`
font-size:40px;
padding-top:20px;
padding-left:50%;
cursor:pointer;

@media screen and (max-width:1030px){    
    font-size:40px;
    padding-top:10px;
    padding-left:20%;
       
}`;


