import styled from 'styled-components'

export const MissieContainer = styled.div`
display:flex;
width:100%;
height:50%;
margin-left:15%;
justify-content:space-around;
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
@media screen and (max-width:1030px){
  display: block;
  margin-left:2%;
      
}
`;

export const MissieWrapper = styled.div`
width:40%;
height:100%;
@media screen and (max-width:1030px){
  width:95%;
      
}


`;

export const TextContainer = styled.div`
display: block;
text-align:left;
padding:30px;
border-radius:10px;
box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.1);
`

export const TextH1 = styled.h1`
width:100%;
color:#08bfc0;
font-size:40px;
font-weight:normal;
font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
`

export const TextP = styled.p`
width:100%;
font-size:18px;
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
`

export const Img = styled.img`
border-radius:50px;
width:70%;
max-height:620px;
object-fit:cover;

`