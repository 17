import styled from 'styled-components'

export const PraktijkContainer = styled.div`
@media screen and (max-width:1030px){
        
        padding:3px;
        

}


display: flex;
width:75%;
height:400px;
border: None;
    
flex-direction:column;
border-top-right-radius:30px;
border-top-left-radius:30px;
background:#08bfc0;
box-shadow: 6px 7px 15px -2px rgba(0,0,0,0.45);

@media screen and (max-width:1030px){      
        width:100%;
    }

/* box-shadow: 5px 5px 23px -3px #000000; */
`;

export const ItemContainer = styled.div`
height:10%;
width:90%;
margin-top:30px;
margin-left:15px;
background:white;
border-radius:50px;
`;

export const TextPar = styled.p`
text-align:left;
margin-top:10px;
`;
