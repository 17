import React,{useState} from 'react'
import { useForm } from "react-hook-form";
import { Form, FormContainer,FormWrapper,ClipDiv,InputText,InputRadio,Submit,TextContainer, PageContainer,PageItem,SingleDiv,TextP,TextH1,TextDiv} from './elements'
import Header from '../Header'
import Praktijk from '../Praktijk'
import {ClipLoader} from "react-spinners"

const SignIn = ({text})=>{
    let [loading, setLoading] = useState(false);
    
    const [sent,setSent] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm();

    function refreshPage() {
        window.location.reload(false);
      }
   
    const onSubmit = async(data,e) =>{

        e.preventDefault();
        setLoading(true)
        await fetch("https://jtzuid.nl/sendschiedam", {
        method: "POST",
        mode:'cors',
        headers: {
            "Content-type": "application/json",
     },
     body: JSON.stringify({data}),
   })
     .then((res) => res.json()).then(async (res) => {
        const resData = await res;
        if (resData.status === "Email sent") {
            setLoading(false)
            setSent(true)
            alert('U bent ingeschreven')
            refreshPage()
        }
      })    


 };
   

    return(
        <div>
        <Header text = {text}/>
        <PageContainer>

        <PageItem style = {{display:'block'}}>
                <PageContainer  style = {{display:'flex',flexDirection:'column'}}>
                    <TextDiv>

                   
                    <TextH1 style = {{textAlign:'left'}}>Inschrijven</TextH1>
                    <TextP>U kunt zich makkelijk inschrijven door het formulier in te vullen en vervolgens te klikken op verzenden.
                        Wij bellen u vervolgens binnen enkele dagen om een afspraak in te plannen.</TextP>
                        </TextDiv>  
                </PageContainer>
                <SingleDiv>
                <Praktijk>

                </Praktijk>
                </SingleDiv>
            
            </PageItem>
            <PageItem >
            <FormContainer style = {{marginTop:'5%'}}>
    
    <FormWrapper>
    <Form onSubmit={handleSubmit(onSubmit)}> 
        <TextContainer>
            <p>Achternaam*</p> &emsp; &emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;
            {errors.Achternaam && <p style = {{color:'red'}} >{errors.Achternaam.message}</p>}
        </TextContainer>
        <InputText type="text" placeholder="Achternaam" {...register("Achternaam", {required: "Dit veld is verplicht.", maxLength: 80, message:"Te veel letters"})} />
        

        <TextContainer>
            <p>Voorletters*</p>  &emsp; &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            {errors.Voorletters && <p style = {{color:'red'}}>{errors.Voorletters.message}</p>}
        </TextContainer>
        <InputText type="text" placeholder="Voorletters" {...register("Voorletters", {required: "Dit veld is verplicht.", maxLength: 100})} />
    
        
        <TextContainer>
            <p>Roepnaam</p>  &emsp; &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            {errors.Roepnaam && <p style = {{color:'red'}}>{errors.Roepnaam.message}</p>}
        </TextContainer>
        <InputText type="text" placeholder="Roepnaam" {...register("Roepnaam", {required: false, maxLength: {value: 80, message:"Te veel letters."}})} />

        <TextContainer>
            <p>Geslacht*</p>  &emsp; &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            {errors.geslacht && <p style = {{color:'red'}}>{errors.geslacht.message}</p>}
        </TextContainer>

        <InputRadio type="radio" value="jongen" {...register("geslacht", {required: "Dit veld is verplicht."})} />
        <label  style = {{float:'left'}}> Jongen</label>
        <br/>
        <InputRadio type="radio" value="meisje" {...register("geslacht", {required: "Dit veld is verplicht."})} />
        <label style = {{float:'left'}}> Meisje</label>
        
        <TextContainer style = {{marginTop:'5%'}}>
            <p>Geboortedatum*</p> &ensp;&emsp;&emsp;&emsp;&emsp;&emsp;
            {errors.Geboortedatum && <p style = {{color:'red'}}>{errors.Geboortedatum.message}</p>}
        </TextContainer>

        <InputText type="text" placeholder="Geboortedatum: (dd-mm-jjjj)" {...register("Geboortedatum", {required: 'Dit veld is verplicht.'})} />
        

        <TextContainer>
            <p>Email</p>  &emsp; &emsp;&emsp; &emsp;&emsp; &emsp;&emsp; &emsp;&emsp; &emsp;&emsp;
            {errors.Email && <p style = {{color:'red'}}>Er is een fout</p>}
        </TextContainer>
        <InputText type="text" placeholder="Email" {...register("Email", { pattern: /^\S+@\S+$/i})} />
    

    </Form>
    </FormWrapper>
    
    <FormWrapper >
    <Form onSubmit={handleSubmit(onSubmit)}>
        <TextContainer>
            <p>Adres*</p>  &emsp; &emsp;&emsp; &emsp;&emsp; &emsp;&emsp; &emsp;
            {errors.Adres && <p style = {{color:'red'}}>{errors.Adres.message}</p>}
        </TextContainer>
        <InputText type="text" placeholder="Adres" {...register("Adres", {required: "Dit veld is verplicht.", maxLength: 100})} />

        <TextContainer>
            <p>Postcode*</p>  &emsp; &emsp;&emsp; &emsp;&emsp; &emsp;&emsp; 
            {errors.Postcode && <p style = {{color:'red'}}>{errors.Postcode.message}</p>}
        </TextContainer>
        <InputText type="text" placeholder="Postcode" {...register("Postcode", {required: "Dit veld is verplicht.", maxLength: 100})} />
       
        <TextContainer>
            <p>Woonplaats*</p>  &emsp; &emsp;&emsp; &emsp;&emsp; &emsp;
            {errors.Woonplaats && <p style = {{color:'red'}}>{errors.Woonplaats.message}</p>}
        </TextContainer>
        <InputText type="text" placeholder="Woonplaats" {...register("Woonplaats", {required: "Dit veld is verplicht.", maxLength: 100})} />
        
        
        <TextContainer>
            <p>Telefoonnummer optie 1*</p>  &emsp; &emsp;
            {errors.TelefoonnummerEen && <p style = {{color:'red'}}>{errors.TelefoonnummerEen.message}</p>}
        </TextContainer>
        <InputText type="tel" placeholder="Telefoonnummer (optie 1)" {...register("TelefoonnummerEen", {required: "Dit veld is verplicht.", maxLength: 10,  pattern: {value: /06[0-9]{8}/i, message: 'Er bevat een fout'}})} />
     
        <TextContainer>
            <p>Telefoonnummer optie 2</p>  &nbsp;
            {errors.TelefoonnummerTwee && <p style = {{color:'red'}}>{errors.TelefoonnummerTwee.message}</p>}
        </TextContainer>
        <InputText type="tel" placeholder="Telefoonnummer (optie 2)" {...register("TelefoonnummerTwee", { maxLength: 10, message: 'Er bevat een fout'})} />
        {/* pattern: {value: /06[0-9]{8}/i, */}
        <TextContainer>
            <p>Opmerking</p> &emsp; &emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;
            
        </TextContainer>
        <textarea style = {{width:'95%',heigth:'200%'}} {...register("Opmerking", {})} />
        {/* {(sent) && <p style = {{textAlign:'left', color:'darkgreen', fontSize:'30px'}}>Verzonden!</p>} */}
        <Submit type="submit" />
        <ClipDiv>
        {loading && <ClipLoader />}
        </ClipDiv>

        
                </Form>
            </FormWrapper>
        </FormContainer>
            </PageItem>


        </PageContainer>
        </div>
       
    )

}

export default SignIn

