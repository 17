import styled from 'styled-components'
import img from '../../images/background.jpg'


export const WelcomeContentWrapper = styled.div`
    margin-top:2%;
    display:flex;
  
    width:100%;
    height:500px;
    align-items:center;
    justify-content:center;
    flex-wrap:flex;
    background-image:url(${img});
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center;

    @media screen and (max-width: 950px){
        margin-top:0%;
        background-size:cover;
}


`;

export const CoronaUpdateWrapper = styled.div`
    display:absolute;
    height:10%;
    background:red;
    width:50%;
    z-index:999;

`

export const Img = styled.img`
    width:100%;
    height:500px;

`

export const Button = styled.button`
font-size:19px;
width:auto;
height:auto;
margin:20px;
font-weight:bold;
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
background:white;
border: 3px solid black;
padding: 20px;
border-radius: 5px;
display:${({isOpenCorona}) =>(isOpenCorona && 'none')};
cursor:pointer;
&:hover{
  background: #d2f2f2;
}


@media screen and (max-width:1030px){
        padding:10px;
        
}   
`;

