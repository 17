import React, { useState } from 'react';
import {Nav,Img,NavLink,NavMenu,TextP, Icon,IconDiv, IconContainer, MobileIcon, IconSecond,ImgLink,ContactHidden,ContactDiv,NavLinks,LogoDiv,IconContainerTwo,SmallButton} from './elements'
import logo from '../../images/logo.png'
import {FaFacebook, FaInstagram,FaBars,FaRegBuilding} from "react-icons/fa"





const Navbar = ({toggle}) => {

    const [isOpenContact,setIsOpenContact] = useState(false)

    const onMouseEntered = () =>{
        setIsOpenContact(true)
      }


    const onMouseLeaved = () =>{
        setIsOpenContact(false)
    }
 
    return (
        <div>
          

           <LogoDiv>
                <ImgLink  to = '/'>
                    <Img     style = {{cursor:'pointer'}} src = {logo}/>
                </ImgLink>

                <IconDiv>
                <IconContainerTwo>
                    <IconSecond >  
                    
                        <FaRegBuilding/>
                       
                    </IconSecond>
                   
                </IconContainerTwo>
                <TextP >Westvest 14 <br/> Schiedam</TextP>
                
                <SmallButton>
                <ImgLink  to = 'spoedgevallen'>
                    Spoedgeval
                    </ImgLink>
                    </SmallButton>
             
                
                </IconDiv>
            </LogoDiv>

           
            <Nav>
                {/* <Bars /> */}
                <MobileIcon onClick = {toggle}>
                    <FaBars/>
                </MobileIcon>
                
                
                <NavMenu >
                    <NavLink to='/' >
                    HOME 
                    </NavLink>
                    
                    <NavLink to='missie' >
                    MISSIE
                    </NavLink>
                    <NavLink to='inschrijven'  >
                    INSCHRIJVEN
                    </NavLink>
                    <NavLink to='team'>
                    TEAM
                    </NavLink>

                    <NavLink to='behandelen' >
                    BEHANDELEN  
                    </NavLink>

                    <ContactDiv onMouseEnter = {onMouseEntered} onMouseLeave = {onMouseLeaved}>                   
                    <NavLink to='#'  > 
                    CONTACT
                    </NavLink>

                     <ContactHidden isOpen = {isOpenContact}  >

                     <NavLinks  onClick = {onMouseLeaved}  to='contact' >
                         <p>Contact en Openingstijden </p> 
                    </NavLinks>
                    <NavLinks onClick = {onMouseLeaved}  to='klachten' >
                    <p>Klachten</p>  
                    </NavLinks>
                    <NavLinks  onClick = {onMouseLeaved} to='spoedgevallen' >
                    <p>Spoedgevallen</p>  
                    </NavLinks>

                    </ContactHidden>
                    </ContactDiv> 
                    
                  
                    
                    
                </NavMenu>     
                   <IconContainer>
                   <p > VOLG ONS:  </p>
                   
                   <Icon>
                   <a style = {{color:'inherit'}} href="https://www.instagram.com/jeugdtandarts_schiedam/"> <FaInstagram /></a>
                        
                    </Icon>
                    <Icon>
                    <a style = {{color:'inherit'}} href="https://nl-nl.facebook.com/JeugdTandartsSchiedam/"> <FaFacebook /></a>
                        
                    </Icon>
                    </IconContainer>
                   
            </Nav>
        </div>
    )
}


export default Navbar

