import React from 'react'
import {HeaderContainer} from './elements'

const Header = ({text})=>{

    return(

        <HeaderContainer>{text}</HeaderContainer>
    )


}

export default Header