import React from 'react'
import {BehandelenContainer, BehandelenWrapper, Text, TextHeader, Img,PageContainer,TextDiv,BehandelenWrapperSecond,BehandelenWrapperMobile} from './elements'
import img1 from '../../images/behandelen1.jpg'
import img2 from '../../images/behandelen2.jpg'
import img3 from '../../images/tandtrek.png'
import img4 from '../../images/smiley.jpg'

import fb3 from '../../images/fb3.jpg'
import tand from '../../images/tand.png'
import Header from '../Header'

const Behandelen = ({text}) =>{

    return(
     <PageContainer>   
        <Header text= {text}/>
        <BehandelenContainer>
         <BehandelenWrapper>
             <TextDiv>
            <TextHeader>Halfjaarlijkse controle’s</TextHeader>
                <Text>  Naast klinische en röntgenologische screening wordt er ruim tijd besteed aan de mondhygiëne van het kind.
                        Preventie staat bij ons vooraan op de lijst. 
                        Voorkomen is beter dan genezen. Bij ieder controle volgt er een poetsles.
                        Bij kinderen tot 10 jaar worden de ouders betrokken bij de poetslessen. 
                        Zo nodig wordt er ook een fluorideapplicatie gedaan.</Text>
                        </TextDiv>
         </BehandelenWrapper>

         <BehandelenWrapper>
            <Img src = {img1}></Img>
         </BehandelenWrapper>
     </BehandelenContainer>  
     <BehandelenContainer>


         <BehandelenWrapperSecond>
            <Img src = {img4}></Img>
         </BehandelenWrapperSecond>
         <BehandelenWrapper>
             <TextDiv>
            <TextHeader>Preventieve behandelingen</TextHeader>
                <Text>  Bij ieder kind wordt de cariësrisico bepaald.
                        Dit houdt in hoe hoog het risico van het kind is op het krijgen van gaatjes.
                        Om te voorkomen dat er gaatjes ontstaan zijn soms preventieve behandelingen nodig zoals fluoride-applicaties of sealants. 
                        Sealants zijn een dun beschermlaagje dat de groeven van een kies opvult.
                        Hierdoor blijven etensresten niet tussen de groefjes van de kiezen.</Text>
                </TextDiv>
         </BehandelenWrapper>
         <BehandelenWrapperMobile>
            <Img src = {img4}></Img>
         </BehandelenWrapperMobile>
     </BehandelenContainer>   
     <BehandelenContainer>
         <BehandelenWrapper>
             <TextDiv>
            <TextHeader>Vullingen</TextHeader>
                <Text>  Als bij de halfjaarlijkse controle gaatjes worden gezien maken we een afspraak voor het vullen van de gaatjes.
                        Dit houdt in dat het geïnfecteerde gedeelte van de tand wordt schoongemaakt en weer wordt opgevuld met vulmateriaal.</Text>
                        </TextDiv>
         </BehandelenWrapper>

         <BehandelenWrapper>
            <Img src = {img2}></Img>
         </BehandelenWrapper>
     </BehandelenContainer>   
     <BehandelenContainer>
         <BehandelenWrapperSecond>
            <Img src = {img3}></Img>
         </BehandelenWrapperSecond>
         <BehandelenWrapper>
             <TextDiv>
            <TextHeader>Trekken van een tand</TextHeader>
                <Text>  Soms is een melktandje niet meer te behouden in verband met een groot gaatje of ontsteking/abces.
                        Soms is dan het trekken de beste oplossing om de ontsteking te genezen en de pijn te behandelen.</Text>
                        </TextDiv>
         </BehandelenWrapper>
         <BehandelenWrapperMobile>
            <Img src = {img3}></Img>
         </BehandelenWrapperMobile>
     </BehandelenContainer> 

     <BehandelenContainer>
         <BehandelenWrapper>
             <TextDiv>
            <TextHeader>Rvs kroon</TextHeader>
                <Text>  
                Sommige kinderen zijn angstig voor de tandarts en het lukt soms niet bij hen een gaatje te vullen. Het kind kan erg schrikken van bijvoorbeeld water in de mond, geluid van de afzuiger en het gevoel van het boortje op de kies. Ook is het soms moeilijk om bij deze kinderen te verdoven.

Dan is er een oplossing om een RVS kroontje te plaatsen op het kiesje. Kiesje wordt drooggemaakt met een klein watje en het kroontje met cement kan zo over het kiesje worden geplaatst.
Om ruimte voor het kroontje te maken wordt er minimaal 4 uur ervoor een elastiekje geplaatst tussen de kiesjes.

Het kind kan misschien een dagje een beetje last hebben van het kroontje. Hij kan ook in het begin een beetje hoog aanvoelen. De hoogte past zich binnen een paar dagen aan. RVS kroontjes hebben een zeer goede prognose. De kiesjes met een kroontje wisselen ook zonder problemen.</Text>
                        </TextDiv>
         </BehandelenWrapper>

         <BehandelenWrapper>
            <Img src = {tand}></Img>
         </BehandelenWrapper>
     </BehandelenContainer>    
     </PageContainer> 
    )

}


export default Behandelen