import React from 'react'
import {PraktijkContainer,ItemContainer,TextPar} from './elements'
import {FaSearchLocation,FaGenderless} from 'react-icons/fa'
import {FiPhoneCall} from 'react-icons/fi'
import {AiOutlineMail} from 'react-icons/ai'
import {HiOutlineLocationMarker} from 'react-icons/hi'

const Praktijk = () =>{

    return(
      
        <PraktijkContainer>
            <ItemContainer>
                <TextPar><FaSearchLocation style = {{marginLeft: '20px'}}/> &emsp;<b>Plaats:</b> &emsp;Schiedam</TextPar>
            </ItemContainer>
            
            <ItemContainer>
                <TextPar><HiOutlineLocationMarker  style = {{marginLeft: '20px'}}/> &emsp; <b>Adres:</b> &emsp;Westvest 14</TextPar>
            </ItemContainer>
            <ItemContainer>
                <TextPar><FaGenderless  style = {{marginLeft: '20px'}}/> &emsp;<b>Leeftijd:</b> &nbsp; 0-18 jaar</TextPar>
            </ItemContainer>
            <ItemContainer>
                <TextPar> <FiPhoneCall  style = {{marginLeft: '20px'}}/> &emsp;<b>Tel:</b> &emsp;&emsp; &nbsp;010-304 82 70</TextPar>
            </ItemContainer>
            <ItemContainer>
                <TextPar><AiOutlineMail style = {{marginLeft: '20px'}} /> &emsp;<b>Mail:</b>&emsp; &ensp;info@jtschiedam.nl</TextPar>
            </ItemContainer>

        </PraktijkContainer>
    
    )
} 

export default Praktijk