import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom';



export const Nav = styled.nav`
 
  /* background: rgb(0,124,124);
background: linear-gradient(90deg, rgba(0,124,124,1) 0%, rgba(255,255,255,1) 100%); */
  background: rgb(126,114,123);
  background: linear-gradient(90deg, rgba(126,114,123,1) 0%, rgba(7,191,191,1) 0%, rgba(255,255,255,1) 100%);
  display: flex;
  height: 75px;
  width:100%;
  justify-content: space-between;
  z-index: 10;

`;

export const NavMenu = styled.ul`
margin: 0 auto;
display: flex;
height: 100%;

align-items: center;
text-align: center;
@media screen and (max-width: 950px){
  display:none;

}`;

export const NavLink = styled(Link)`
  margin:30px;
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  &:hover{
  color: #607f7c;
}
  
`;

export const NavLinks = styled(Link)`

  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  margin-left:5%;
  cursor: pointer;
  &:hover{
  color: #08bfc0;;
}
  
`;

export const Icon = styled.div`
margin-left: 10px;
margin-top: 3px;
cursor: pointer;
&:hover{
  color: gray;
}
`;
export const IconSecond = styled.div`
margin-left: 10px;
margin-top: 3px;

color: #06a3a4;

`;

export const IconContainer = styled.div`
display: flex;

align-items:center;
margin-right:150px;
@media screen and (max-width: 950px){
 
}

`;

export const Img = styled.img`
    /* position:absolute;
    left:50px;
    top:30px;
    width: 338px;
    height:55px; */

   
`

export const ImgLink = styled(Link)`
color:white;
text-decoration:none;

`;






export const Button = styled.button`
margin-left:100px;
background:none;
border: 1px solid black;
padding: 15px;
border-radius: 5px;
cursor:pointer;
&:hover{
  background: #d2f2f2;
}
`;


export const MobileIcon = styled.div`
display:none;

@media screen and (max-width: 950px){
  display:block;
  font-size:25px;
  padding:2px;
  transform: translate(100%,25%);
  cursor:pointer;
}
`;

export const ContactHidden = styled.div`
display: ${({isOpen}) => (isOpen) ?'block':'none'};
position:absolute;

text-align:left;
margin-left:2%;

background:white;
box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.2);
width:210px;

`;
export const ContactDiv = styled.div`
display:block;


`;

export const IconContainerTwo = styled.div`
display: flex;
border:1px solid black;

margin-left:42%;
align-items:center;
height:50px;
margin-top:0.5%;
padding-right:10px;
border-radius:5px;
font-size:30px;
@media screen and (max-width: 950px){
  margin-right:30px;
}

`;

export const LogoDiv = styled.div`
display:flex;
text-align:left;
margin-top:2%;
margin-left:2%;
margin-bottom:1%;
flex-wrap:flex;


`

export const SmallButton = styled.button`
font-size:16px;
margin-left:10%;
margin-top:0.5%;
height:50px;
font-weight:bold;
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
padding-left:4.5%;
padding-right:4.5%;
background:#06a3a4;
border-radius:5px;
cursor: pointer;
color:white;
border:none;
@media screen and (max-width: 1300px){
  margin-left:0%;
}
&:hover{
  background:#10c8c9;
}

`;

export const IconDiv = styled.div`
display:flex;
margin-left:20%;


@media screen and (max-width: 1300px){
  margin-left:2%;
}

@media screen and (max-width: 950px){
  display:none;
}
`
export const TextP = styled.p`
text-align:left;
color:#525757;
width:500px;
margin-left:3%;

`;



