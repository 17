import React, { useState } from 'react';
import {BrowserRouter as Router,Routes,Route, Navigate} from 'react-router-dom'
import Behandelen from './Components/Behandelen';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Home from './Components/Homepage';
import Klachten from './Components/Klachten';
import Missie from './Components/Missie';
import SignIn from './Components/SignIn';
import Spoedgeval from './Components/Spoedgeval';
import Team from './Components/Team';
import Sidebar from './Components/Sidebar';
import Navbar from './Components/Navbar';
import ScrollToTop from './Components/ScrollToTop';

function App() {

  const [isOpen,setIsOpen] = useState(false)
  const [isOpenContact,setIsOpenContact] = useState(false)
  const [isOpenCorona,setIsOpenCorona] = useState(false)
  const [isOpenTeam,setIsOpenTeam] = useState(false)
  

  const toggle = () =>{
    setIsOpen(!isOpen)
  }

  const toggleContact = () =>{
    setIsOpenContact(!isOpenContact)
  }

  const toggleCorona = () =>{

    setIsOpenCorona(!isOpenCorona)

  }

  
  const toggleTeamTrue = () =>{

    setIsOpenTeam(true)

  }
  const toggleTeamFalse = () =>{

    setIsOpenTeam(false)

  }


  return (
    <div>
    <Router>
    <ScrollToTop>
      <Sidebar isOpen = {isOpen}  toggle = {toggle}/>
        <Navbar toggle = {toggle}  />
        <Routes>
              <Route path="/" element={<Home isOpenCorona = {isOpenCorona} toggleCorona = {toggleCorona}/>} />
              <Route path="/behandelen" element={<Behandelen text = "Behandelen"></Behandelen>} />
              <Route path="/contact" element={<Contact text = "Contact"></Contact>} />
              <Route path="/klachten" element={<Klachten text = "Klachten"></Klachten>} />
              <Route path="/missie" element={<Missie text = "Missie"></Missie>} />
              <Route path="/spoedgevallen" element={<Spoedgeval text = "Spoedgeval"></Spoedgeval>} />
              <Route path="/team" element={<Team isOpenTeam = {isOpenTeam} toggleTeamFalse = {toggleTeamFalse} toggleTeamTrue = {toggleTeamTrue}  text = "Team"></Team>} />
              <Route path="/inschrijven" element={<SignIn text = "Inschrijven"></SignIn>} />
             
        </Routes>
        <Footer/>
        </ScrollToTop>
    </Router>

</div>
  );
}

export default App;
