import styled from 'styled-components'



export const HeaderContainer = styled.div`
display:flex;
padding:10px;
width:100%;
height:50px;
font-size:30px;
margin-bottom:100px;

justify-content:center;
align-content:content;

box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.46);

`;